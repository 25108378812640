@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap');

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 356px !important;
    font-family: 'Inter', sans-serif;
}

button {
    font-family: 'Lato', sans-serif !important;
}

*,
:after,
:before {
    box-sizing: border-box;
}
