/* CARDS */
.CelebrityNftCard {
	display: inline-block;
	width: 100%;
	height: 403px;

	margin: 13px 0px 13px 0px;

	box-sizing: border-box;

	overflow: hidden;
	border-radius: 9px;
	background-color: white;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

	transition: transform 0.2s ease-in;
}

.CelebrityNftCard:hover {
	transform: scale(1.05);
	transition: transform 0.3s ease-in;
}

/****************************************/

/* Image */
.CelebrityNftImage {
	display: inline-block;
	width: 100%;
	height: 275px;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: relative;
}

.VideoContainer {
	display: inline-block;
	width: 100%;
	height: 275px;
	position: relative;
}

.ButtonPlayNFT {
	position: absolute;
	top: 4px;
	left: 7px;
}

/* Video */
.BackgroundVideo {
	display: inline-block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.CelebrityNftDataContainer {
	display: flex;
	height: 25%;
	padding: 0px 15px 0px 20px;
	margin: 0px;
}

.CelebrityNftData {
	display: flex;
	width: 65%;
	flex-direction: column;
	justify-content: center;

	text-align: left;
}

.CelebrityNftData h3 {
	display: inline-block;
	color: #868686;
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
}

.CelebrityNftData h2 {
	display: inline-block;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.CelebrityNftPrice {
	display: flex;
	width: 41%;
	flex-direction: column;
	justify-content: center;
}

.CelebrityNftPrice h4 {
	display: inline-block;
	color: #868686;
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	text-align: right;
}

.CelebrityNftPrice h5 {
	display: inline-block;
	color: #868686;
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 18px;
	text-align: right;
	

	background: #7231da;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/***************/
/* TABLET MODE */
/***************/

/* TABLET */

@media only screen and (min-width: 480px) and (max-width: 899px) {
	.CelebrityNftCard {
		width: calc(50% - 26px);
		margin: 13px;
	}
}

@media only screen and (min-width: 900px) {
	/* CARD */
	/* BUY BUTTON */
	.CelebrityNftBuy {
		width: 70px;
		height: 22px;
	}
}

/********/
/* 	PC  */
/********/

/* PC SMALL */

@media only screen and (min-width: 900px) and (max-width: 1199px) {
	/* CARDS */

	.CelebrityNftCard {
		width: calc(33% - 26px);
		margin: 13px;
	}
}

@media only screen and (min-width: 900px) {
	/* CARD */
	/* BUY BUTTON */
	.CelebrityNftBuy {
		width: 70px;
		height: 22px;
	}
}

/* PC */

@media only screen and (min-width: 1200px) {
	/* CARDS */

	.CelebrityNftCard {
		width: calc(25% - 26px);
		margin: 13px;
	}
}
