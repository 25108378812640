.Sidebar_head__1Z76C {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0 22px;
    overflow: hidden;
    width: 100%;
}

@media (min-width: 1441px) {
    .Sidebar_logo__3TLMd {
        width: 130px;
    }
}

.Sidebar_logo__3TLMd {
    width: 130px;
    display: block;
    margin: 0 auto;
}

@media (min-width: 1441px) {
    .Sidebar_logo__3TLMd {
        width: 130px;
    }
}

@media (min-width: 768px) {
    .Sidebar_wallet__eXx60 {
        display: none;
    }
}

@media (max-width: 767px) {
    .Sidebar_logo__3TLMd {
        display: none;
    }

    .Sidebar_wallet__eXx60 {
        display: block;
    }
}

.Sidebar_buttonConnect__small {
    width: 100%;
    border-radius: 6px;
    background-color: #805eaa;
    color: #fff;
    transition: opacity 0.15s ease-in;
    padding: 0 20px;
    min-height: 30px;
    font-size: 11px;
    font-weight: 600;
    font-family: Rubik, sans-serif;
    letter-spacing: 0.55px;
    line-height: 9px;
    cursor: pointer;
    outline: 0;
}

.Sidebar_buttonConnect__small:hover {
    opacity: 0.9;
    color: #fff;
}

.Sidebar_items__1Hqtp {
    list-style: none;
    margin-top: 30px;
}

.Sidebar_item__rJc2e:not(:first-child) {
    margin-top: 12px;
}

@media (min-width: 1441px) {
    .Sidebar_link__3B0JM {
        font-size: 15px;
        height: 50px;
    }
}

.Sidebar_icon__2uqhU {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    margin-right: 13px;
}

@media (min-width: 1441px) {
    .Sidebar_icon__2uqhU svg {
        height: 14px !important;
    }
}

.Sidebar_icon__2uqhU svg {
    height: 11px;
    max-width: 20px;
}

.Sidebar_icon__2uqhU svg path {
    fill: #828384;
    transition: all 0.15s ease-in;
}

.Sidebar_link__3B0JM {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    padding: 0 18px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.6px;
    text-align: left;
    color: #A7A7A7;
    transition: all 0.15s ease-in;
}

.Sidebar_link__3B0JM:hover {
    color: #FFFFFF;
}

@media (min-width: 1441px) {
    .Sidebar_link__3B0JM {
        font-size: 15px;
        height: 50px;
    }
}

@media (max-width: 767px) {
    .Sidebar_link__3B0JM {
        padding: 0 17px;
    }
}

@media (max-width: 767px) {
    .Sidebar_items__1Hqtp {
        margin-top: 10px;
    }
}

.Sidebar_link__3B0JM_active {
    /* background: linear-gradient(90deg, #7A61FF 0%, rgba(122, 97, 255, 0.6) 100%); */
    border-left: 4px solid #7231da;
    color: #fff;
}

.Sidebar_link__3B0JM_active .Sidebar_icon__2uqhU svg path {
    fill: #fff;
}

.Sidebar_link__3B0JM_active:hover {
    opacity: 0.85;
    color: #fff;
}

a:active,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

.Sidebar_link__3B0JM:hover {
    opacity: 0.85;
    color: #7231da;
}

svg:not(:root) {
    overflow: hidden;
}

.sidebar-wrapper {
    height: 100%;
}

.sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.Sidebar_bottom__3eeR3 {
    display: flex;
    justify-content: center;
}

.social-grp div {
    padding: 5px 0;
}

.border-line {}

.guides {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.social-grp {
    border-right: 1px solid rgb(167, 167, 167);
    padding-right: 20px;

}

.guides div{
    color: rgb(167, 167, 167);
    padding: 5px;
    margin-left: 20px;
}

.guides div:hover{
    color: #7231da;
}

.Sidebar_bottom__3eeR3 a {
    text-decoration: none;
    color: inherit;
}

.Sidebar_bottom__3eeR3 a.add-image-atag {
    width: 100%;
    padding: 0 17px;
}

.Sidebar_bottom__3eeR3 a:hover {
    text-decoration: none;
    color: inherit;
}

.Sidebar_pancake__1_ko8 {
    width: 100%;
    max-width: 203px;
    padding: 0 20px;
}

.Sidebar_media__3EY89 {
    list-style: none;
    margin: 18px 0 0;
    padding: 5px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 16px);
    grid-gap: 8px;
    width: 100%;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

@media (min-width: 1441px) {
    .Sidebar_media__3EY89 {
        grid-gap: 18px;
    }
}

@media (max-width: 767px) {
    .Sidebar_media__3EY89 {
        grid-gap: 10px !important;
        display: flex;
        padding: 0 20px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media (min-width: 1441px) {
    .Sidebar_mediaIcon__2HcGh {
        height: 20px !important;
    }
}

.Sidebar_mediaIcon__2HcGh {
    height: 16px;
    fill: #b8b8b8;
}

.Sidebar_mediaIcon__2HcGh:hover {
    fill: #ffcc33;
}

.pancakse-swap {
    border-radius: 5px;
    border: 1px solid rgb(209, 206, 206);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    max-width: 203px;
    padding: 7px 14px;
    cursor: pointer;
}

.pancakse-swap div:first-child svg {
    height: 40px;
}

.pancakse-swap div:first-child svg path {
    fill: #d1884f;
}

.tribe-pancake {
    margin-left: 5px;
}

.tribe-small-text {
    font-size: 9px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #6b6c6d;
}

.pancake-small-text {
    font-weight: 900;
    line-height: 0.8;
    font-family: Rubik, sans-serif;
    font-size: 20px;
}

@media (max-width: 768px) {
    .pancakse-swap {
        border-radius: 5px;
        border: 1px solid rgb(209, 206, 206);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        max-width: 170px;
        padding: 4px 7px;
        cursor: pointer;
    }

    .pancakse-swap div:first-child svg {
        height: 40px;
    }

    .pancakse-swap div:first-child svg path {
        fill: #d1884f;
    }

    .pancakse-swap div img {
        height: 25px;
    }

    .tribe-pancake {
        margin-left: 5px;
    }

    .tribe-small-text {
        font-size: 7px;
        font-weight: 700;
        letter-spacing: 0.04em;
        color: #6b6c6d;
    }

    .pancake-small-text {
        font-weight: 900;
        line-height: 0.8;
        font-family: Rubik, sans-serif;
        font-size: 17px;
    }
}

@media (min-width: 1441px) {
    .Sidebar_pancake__1_ko8 {
        max-width: 240px;
    }

    .pancakse-swap {
        max-width: 240px;
    }
}

@media (min-width: 1441px) {
    .Sidebar_pancake__1_ko8 {
        max-width: 240px !important;
    }

    .pancakse-swap {
        max-width: 240px !important;
    }
}

@media screen and (min-width: 752px) and (max-width: 1441px) {
    .pancakse-swap {
        border-radius: 5px;
        border: 1px solid rgb(209, 206, 206);
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        max-width: 200px;
        padding: 4px 7px;
        cursor: pointer;
    }

    .pancakse-swap div:first-child svg {
        height: 5px;
    }

    .pancakse-swap div:first-child svg path {
        fill: #d1884f;
    }

    .pancakse-swap div img {
        height: 35px;
    }

    .tribe-pancake {
        margin-left: 5px;
    }

    .tribe-small-text {
        font-size: 9px;
        font-weight: 700;
        letter-spacing: 0.04em;
        color: #6b6c6d;
    }

    .pancake-small-text {
        font-weight: 900;
        line-height: 0.8;
        font-family: Rubik, sans-serif;
        font-size: 19px;
    }
}

.img-white-logo {
    display: flex;
    gap: 10px;
    padding: 12px 30px;
}

.img-white-logo div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.img-white-logo div img {
    width: 20px;
}

.img-white-logo div p {
    margin: auto;
    margin-top: 2px;
    font-size: 18px;
}

.Sidebar_link__3B0JM_active .img-white-logo {
    opacity: 1;
}

.add-image {
    width: 100%;
}

.Sidebar_info_menu {
    width: 100%;
    list-style: none;
    padding: 0 24px;
    margin: 0 0 16px 0;
}

.Sidebar_info_menu li a {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #A7A7A7;
    padding: 5px 0;
    display: block;
}

.Sidebar_info_menu li a:hover {
    color: #FFFFFF;
}