/****************/
/* MOBILE FIRST */
/****************/

/*Container*/
.Container {
	display: inline-block;
	width: 100%;
	min-height: calc(100vh - 121px);

	padding: 35px;
	text-align: center;

	position: relative;
}

/************************************/

.Section {
	display: inline-block;
	width: 100%;
	max-width: 1000px;
	max-width: 1026px;
	text-align: center;
}

.Title {
	font-family: Lato;
	font-style: normal;
	font-weight: 800;
	font-size: 50px;
	line-height: 150px;
	padding-left: 13px;
	color: #fff;
}

@media (max-width: 576px) { 
.Title {
	font-size:35px;
	line-height: 50px;
	}
  }
