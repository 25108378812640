@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.project-banner-container {
  min-height: 170px;
  background-color: #1c0145;
  padding: 30px 70px;
  color: #ffffff;
}

.banner-container-content {
  min-height: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.banner-img img {
  max-width: 220px;
}

@media (max-width: 767px) {
  .banner-img {
    display: none;
  }

  .project-banner-container {
    max-height: 120px !important;
    padding: 15px 70px;
  }

  .banner-container-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .banner-container-content div {
    width: 100%;
  }

  .banner-container-content div h2 {
    font-size: 35px;
    text-align: center;
  }

  .banner-container-content div p {
    font-size: 12px;
    text-align: center;
  }
}

.projects-launchTitle {
  padding: 38px 0 10px 48px;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 0.9;
  color: #ffffff;
}

.memepad-card {
  border-radius: 12px !important;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  box-shadow: 4.5px 7.8px 45px 0 rgb(34 34 34 / 5%);
  transition: all 1s cubic-bezier(0.17, 0.67, 0.23, 0.99);
}

.memepad-card a {
  text-decoration: none !important;
}

.memepad-card a:hover {
  text-decoration: none !important;
}

@media (max-width: 992px) {
  .memepad-card {
    margin-bottom: 30px;
  }
}

.memepad-card:hover:not(.memepad-card-disable) {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  box-shadow: 4.5px 7.8px 45px rgb(0 0 0 / 20%);
}

.memepad-card-disable {
  cursor: not-allowed;
}

.memepad-card-1st-div {
  width: auto;
  height: 100%;
  background: #ebebeb no-repeat 50%;
  transition: all 1s cubic-bezier(0.17, 0.67, 0.23, 0.99);
  text-align: center;
}

.memepad-card-1st-div img {
  width: 100%;
  height: 240px;
  vertical-align: middle;
  border-style: none;
}

.card-label-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.status-label-img span:first-child {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-right: 5px;
}

.label-bg-color-black {
  background: rgb(79, 80, 81);
}

.label-bg-color-green {
  background: rgb(0, 191, 132);
}

.status-label-img {
  color: rgb(255, 255, 255);
  justify-content: center;
  height: 20px;
  padding: 0 9px;
  border-radius: 5px;
  font-family: Rubik, sans-serif;
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  white-space: nowrap;
  align-items: center;
  display: flex;
  width: auto;
  max-width: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.memepad-card-2nd-div {
  display: flex;
  flex-direction: column;
  padding: 0.8rem;
}

.second-third-div-background {
  background-color: #ffffff;
}

.img-txt {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-txt div:first-child img {
  border-radius: 50%;
}

.only-text div:first-child {
  color: #22282a;
  font-weight: 700;
  line-height: 15px;
}

.only-text span {
  line-height: 0%;
  font-size: 13px;
  color: #838792;
}

.label {
  font-size: 13px;
  color: #22282a;
}

.progress-num {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.memepad-card-3rd-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem;
}
.stats-label {
  font-family: "Rubik", sans-serif;
  font-size: 13px;
  color: #838792;
}

.stats-num {
  font-family: "Rubik", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #22282a;
}

.progress {
  height: 0.5rem !important;
  margin: 0.2rem 0;
}

.small-2nd-div-img {
  position: relative;
  top: 1.2rem;
  bottom: 0;
  right: 1.1rem;
}

.small-2nd-div-img img {
  border-radius: 50%;
}

.green-span {
  border-radius: 2.5px;
  background-color: #d6fae1;
  margin-left: 5px;
  padding: 1px;
  color: #2de866;
  font-weight: 600;
  font-family: Rubik, sans-serif;
  font-size: 12px;
}

.red-span {
  border-radius: 2.5px;
  background-color: #fad6d6;
  margin-left: 5px;
  padding: 1px;
  color: #e82d2d;
  font-weight: 600;
  font-family: Rubik, sans-serif;
  font-size: 12px;
}
