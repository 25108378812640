@media (min-width: 370px) {
  .disconnect-inner-modal-main {
    width: auto;
    min-width: 500px;
    max-width: 100%;
  }
}

.disconnect-inner-modal-main {
  overflow: hidden;
  background: rgb(255, 255, 255);
  box-shadow: rgb(14 14 44 / 10%) 0px 20px 36px -8px,
    rgb(0 0 0 / 5%) 0px 1px 1px;
  border: 1px solid rgb(231, 227, 235);
  border-radius: 15px;
  width: 100%;
  max-height: 100vh;
  z-index: 100;
  outline: 0;
}

.jSaCuW-dis {
  -webkit-box-align: center;
  align-items: center;
  background: rgb(255, 255, 255);
  display: flex;
  padding: 12px 40px;
}

.fdgtVi {
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
}

.kNJaHk {
  display: flex;
}

.htanym {
  padding: 0px;
  width: 48px;
}

.disconnect-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  width: 100%;
}

.dmc1 {
  font-size: 15px;
}

.dmc2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  font-size: 18px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
}

.dmc2 img {
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.dmc3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  color: rgb(172, 163, 163);
}

.dmc3 div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: rgb(172, 163, 163);
  cursor: pointer;
}

.dmc3 button {
  background: none;
  border: 0;
  color: rgb(172, 163, 163);
}

.dmc3 div:first-child svg {
  margin-right: 5px;
  height: 12px;
}

.dmc3 div:last-child svg {
  margin-right: 5px;
}

.dmc3 div:last-child a {
  text-decoration: none;
  color: rgb(172, 163, 163);
}

.disconnect-modal-content-div-2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.disconnect-modal-content-div-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid rgb(230, 225, 225);
  border-radius: 5px;
  padding: 20px;
}

.disconnect-modal-content-div-2 button {
  background: linear-gradient(90deg, #7231da 0%, #5e13d6 100%);
  color: white;
  transition: opacity 0.15s ease-in;
  border: none;
  padding: 0 25px;
  height: 35px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 11.5px;
  justify-content: center;
  transition: opacity 0.15s ease-in;
  font-family: Rubik, sans-serif;
}

.disconnect-modal-content-div-2 button:hover {
  opacity: 0.85;
}
