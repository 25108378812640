.ch-background-container {
	display: inline-block;
	width: 100%;
	height: calc(100vh + 34px);

	position: fixed;
	z-index: 0;
}

/********************************************/

.ch-white {
	height: calc(100vh + 34px);
	&:before {
		z-index: 0;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 60vh;
		background-image: linear-gradient(
			-180deg,
			#ffffff 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
	&:after {
		z-index: 0;
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 20vh;
		background-image: linear-gradient(
			0deg,
			#ebebeb 0%,
			rgba(235, 235, 235, 0) 100%
		);
	}
}

.copos {
	height: 100%;
	display: flex;
	justify-content: space-around;
	overflow: hidden;
}
.copo {
	animation: snow 9.5s linear infinite;
	align-self: flex-end;
	width: 100px;
	height: 100px;
	transform: translateY(100%);
	z-index: 0;

	&:nth-child(1) {
		background: url(./assets/copo1.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(2) {
		height: 75px;
		width: 75px;
		animation-delay: 1s;
		animation-duration: 17s;

		background: url(./assets/copo2.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(3) {
		height: 50px;
		width: 50px;
		animation-delay: 1.5s;
		animation-duration: 8s;

		background: url(./assets/copo3.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(4) {
		height: 60px;
		width: 60px;
		animation-delay: 0.5s;
		animation-duration: 13s;

		background: url(./assets/copo4.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(5) {
		height: 30px;
		width: 30px;
		animation-delay: 4s;
		animation-duration: 11s;

		background: url(./assets/copo2.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(6) {
		height: 70px;
		width: 70px;
		animation-delay: 2s;
		animation-duration: 9s;

		background: url(./assets/copo6.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(7) {
		height: 80px;
		width: 80px;
		animation-duration: 12s;

		background: url(./assets/copo7.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(8) {
		height: 30px;
		width: 30px;
		animation-delay: 5s;
		animation-duration: 18s;

		background: url(./assets/copo1.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(9) {
		height: 70px;
		width: 70px;
		animation-duration: 9s;

		background: url(./assets/copo2.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(10) {
		height: 50px;
		width: 50px;
		animation-delay: 6s;
		animation-duration: 12s;

		background: url(./assets/copo3.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	&:nth-child(11) {
		height: 70px;
		width: 70px;
		animation-delay: 3s;
		animation-duration: 10s;

		background: url(./assets/copo4.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(12) {
		height: 90px;
		width: 90px;
		animation-delay: 1s;
		animation-duration: 8s;

		background: url(./assets/copo5.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(13) {
		height: 20px;
		width: 20px;
		animation-delay: 3s;
		animation-duration: 8s;

		background: url(./assets/copo6.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(14) {
		height: 70px;
		width: 70px;
		animation-delay: 5s;
		animation-duration: 10s;

		background: url(./assets/copo7.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
	&:nth-child(15) {
		height: 50px;
		width: 50px;
		animation-delay: 7s;
		animation-duration: 8s;

		background: url(./assets/copo1.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}
}

@keyframes snow {
	from {
		transform: translateY(calc(-100vh + -100%)) rotate(-50deg);
	}
	to {
		transform: translateY(100%) rotate(20deg);
	}
}
