/* Container */
.Container {
	display: flex;
	width: 100%;
	height: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	position: relative;

	overflow: hidden;
}

/************************************/

.Image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* Video */
.Video {
	display: inline-block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
